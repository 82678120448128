
/* Default Styles (Large Screens) */
.details {
    text-align: center;
    padding: 0;
    position:fixed;
    bottom:0;
    width: 100%;
    font-size: 24px; /* Default size */
   
  }


  
  /* Medium Screens (Tablets) */
  @media (max-width: 1024px) {
    .details{
      font-size: 20px;
    }
  }
  
  /* Small Screens (Phones) */
  @media (max-width: 768px) {
    .details{
      font-size: 18px;
    }
  }
  
  /* Extra Small Screens (Very Small Phones) */
  @media (max-width: 576px) {
    .details{
      font-size: 16px;
    }
  }
  