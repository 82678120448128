.footer {
    padding: 0rem;
    position:fixed;
    bottom: 0;
    width: 100%;
    display: flex;
  }

  .footer-img {
    width: 50px;
    height: 25px;
    letter-spacing: 5px;
    vertical-align: middle;
    }